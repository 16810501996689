<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <!-- billType -->
            <!-- <div class="search_center"> 发票类型：
                <el-select v-model="parameter.billType" class="ipt_width" placeholder="请选择发票类型" clearable>
                  <el-option v-for="item in dictData.combinedBillTypes" :key="item.id" :label="item.dictName"
                    :value="item.dictVal" />
                </el-select>
              </div> -->
            <div class="search_center">
              员工：
              <el-select
                v-model="parameter.userId"
                class="ipt_width"
                filterable
                placeholder="请选择员工"
                clearable
              >
                <el-option
                  v-for="item in staffList"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id"
                />
              </el-select>
            </div>
            <!-- <div class="search_center">
              部门名称：
              <DeptCascader
                :placeholder="'请选择部门'"
                :deptList="deptList"
                v-model="parameter.deptId"
              />
            </div> -->
            <div class="search_center">
              核算月份：
              <el-date-picker
                v-model="parameter.examineMonth"
                type="month"
                value-format="yyyy-MM"
                placeholder="选择日期"
                class="ipt_width"
                clearable
              >
              </el-date-picker>
            </div>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button
              type="warning"
              icon="el-icon-refresh"
              @click="handleReset"
              >清空</el-button
            >
          </div>
        </div>
        <div class="fr">
          <!-- <el-button type="warning " @click="importData">导入</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button> -->
          <!-- 下拉框选择年份，月份 -->
          <!-- <div
            class="search_center"
            style="display: flex; width: 800px; align-items: center"
          >
            <span
              style="white-space: nowrap; line-height: 40px; font-size: 14px"
              >选择时间:
            </span>
            <el-select
              v-model="parameter.year"
              size="mini"
              class="ipt_width"
              placeholder="请选择年份"
              clearable
            >
              <el-option
                v-for="item in dictData.years"
                :key="item.id"
                :label="item.dictName"
                :value="item.dictVal"
              />
            </el-select>
            <el-select
              v-model="parameter.month"
              size="mini"
              class="ipt_width"
              placeholder="请选择月份"
              clearable
            >
              <el-option
                v-for="item in dictData.months"
                :key="item.id"
                :label="item.dictName"
                :value="item.dictVal"
              />
            </el-select>
          </div> -->
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            v-loading="loading"
            ref="multipleTable"
            tooltip-effect="dark"
            height="string"
            border
            :data="tableData"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="50"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="staffName"
              label="员工姓名"
              width="100"
            ></el-table-column>
            <!-- deptName,examineMonth -->
            <el-table-column
              align="center"
              prop="deptName"
              label="部门"
              width="220"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="examineMonth"
              label="月份"
              width="120"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="projectCount"
              label="项目数量"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="businessCount"
              label="商务数量"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="manageTotalHour"
              label="组织审核工时"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="projectTotalHour"
              label="项目审核工时"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="businessTotalHour"
              label="商务审核工时"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="totalAuditHour"
              label="总审核工时（小时）"
            >
              <template slot-scope="scope">
                <!-- {{ calculateTotalHours(scope.row) }} -->
                {{ scope.row.totalAuditHour }}
              </template>
            </el-table-column>
            <el-table-column
              align="right"
              prop="manageTotalHourVariableSalary"
              label="组织浮动薪酬"
            >
              <template slot-scope="scope">
                <a
                  class="a_link"
                  @click="showSalaryDetail(scope.row, 'manage')"
                  >{{
                    scope.row.manageTotalHourVariableSalary | applyAmount
                  }}</a
                >
              </template>
            </el-table-column>
            <el-table-column
              align="right"
              prop="projectTotalHourVariableSalary"
              label="项目浮动薪酬"
            >
              <template slot-scope="scope">
                <a
                  class="a_link"
                  @click="showSalaryDetail(scope.row, 'project')"
                  >{{
                    scope.row.projectTotalHourVariableSalary | applyAmount
                  }}</a
                >
              </template>
            </el-table-column>
            <el-table-column
              align="right"
              prop="businessTotalHourVariableSalary"
              label="商务浮动薪酬"
            >
              <template slot-scope="scope">
                <a
                  class="a_link"
                  @click="showSalaryDetail(scope.row, 'business')"
                >
                  {{ scope.row.businessTotalHourVariableSalary | applyAmount }}
                </a>
              </template>
            </el-table-column>
            <el-table-column
              align="right"
              prop="totalFloatSalary"
              label="总浮动薪酬"
            >
              <template slot-scope="scope">
                <!-- {{ calculateTotalSalary(scope.row) }} -->
                {{ scope.row.totalHourVariableSalary }}
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="操作"
              fixed="right"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  class="text_Details_Bgc"
                  @click.stop="handleDetails(scope.row)"
                >
                  详情
                </el-button>
                <el-button type="warning" size="mini" class="text_Edit_Bgc" @click.stop="handleEdit(scope.row)">
                    编辑
                  </el-button>
                  <el-button type="text" size="mini" class="text_Remove_Bgc" @click.stop="handleDelete(scope.row)">
                    删除
                  </el-button>
              </template>
            </el-table-column> -->
          </el-table>
          <el-pagination
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            :total="parameter.total"
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input
                size="mini"
                v-model.number="pageSize"
                @blur="handlePageSize"
              ></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- <Edit v-if="editShow" :isShow.sync="editShow" :options="options"></Edit> -->
    <Detail
      :isShow.sync="editShow"
      :options="options"
    >
    </Detail>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    Crumbs: () => import("@/components/Crumbs.vue"),
    Edit: () => import("../scoreEdit.vue"),
    Dictionary: () => import("@/components/Dictionary.vue"),
    DeptCascader: () => import("@/components/dept/DeptCascader.vue"),
    Detail: () => import("./detail.vue"),
  },
  data() {
    return {
      loading: false, // 表格 加载
      // 查询条件
      parameter: {
        staffName: "",
        deptName: "",
        order: "",
        orderRule: "",
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      tableData: [], // 表格数据
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      deptList: [], // 部门 列表
      options: {},
      staffList: [], // 员工列表
      dictData: {},
    };
  },
  provide() {
    return {
      re: this.closeSubgroup,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toFixed(2);
      } else {
        return n;
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog;
    this.parameter.pageSize = this.userInfo.pageSizeLog;
    this.getDictData();
    this.getDeptList(); /** 加载 "部门" 列表数据 **/
    this.getTableData(); /** 加载 "发票申请" 数据 **/
  },
  watch: {},
  methods: {
    showSalaryDetail(row, scoreType) {
      this.options = row;
      this.options.title = "工资详情";
      this.options.scoreType = scoreType;
      this.editShow = true;
      // let params = {
      //   scoreType,
      //   userId: row.staffId,
      //   examineMonth: row.examineMonth,
      // };
      // this.$api.compensationManage
      //   .staffSalaryDetail(params)
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    calculateTotalHours(row) {
      return (
        (row.manageTotalHour || 0) +
        (row.projectTotalHour || 0) +
        (row.businessTotalHour || 0)
      );
    },
    calculateTotalSalary(row) {
      return (
        (row.manageTotalHourVariableSalary || 0) +
        (row.projectTotalHourVariableSalary || 0) +
        (row.businessTotalHourVariableSalary || 0)
      );
    },
    handleAdd() {
      this.options = {};
      this.options.disabled = false;
      this.options.isAddAuditor = true;
      this.options.title = "发票详情";
      this.editShow = true;
    },
    getDictData() {
      this.$api.user
        .staffList()
        .then((res) => {
          this.staffList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then((res) => {
          this.deptList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter = {
        order: this.parameter.order,
        orderRule: this.parameter.orderRule,
        pageNow: 1,
        pageSize: this.parameter.pageSize,
        total: this.parameter.total,
      };

      this.getTableData(); /** 加载 "年浮动工资" 数据 **/
    },
    getTableData() {
      this.loading = true;
      this.$api.compensationManage
        .staffSalarySummarylist(this.parameter)
        .then((res) => {
          this.tableData = res.data?.records;
          this.parameter.total = res.data?.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetails(item) {
      this.options = item;
      this.options.disabled = true;
      this.options.isAddAuditor = true;
      this.options.title = "年浮动工资详情";
      this.editShow = true;
    },
    handleEdit(item) {
      this.options = item;
      this.options.disabled = false;
      this.options.isAddAuditor = true;
      this.options.title = "年浮动工资详情";
      this.editShow = true;
    },
    handleDelete(item) {
      this.$confirm("请确认是否删除当前年浮动工资？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api.invoice
          .deleteInvoice(item.id)
          .then((res) => {
            this.$message.success("删除成功");
            this.getTableData();
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    pageChangeHandler(page) {
      this.parameter.pageNow = page;
      this.getTableData(); /** 加载 "发票申请" 数据 **/
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val;
      this.parameter.pageNow = 1;
      this.getTableData();
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1;
      }
      let obj = { pageSizeLog: this.pageSize };
      this.loading = true;
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then((res) => {
          this.loading = false;
          this.parameter.pageSize = this.pageSize;
          this.userInfo.pageSizeLog = this.pageSize;
          localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
          this.getTableData();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    /** 关闭 "编辑" 组件 **/
    closeSubgroup(isRefresh = false) {
      this.editShow = false;
      if (isRefresh === true) {
        this.getTableData(); /** 加载 "发票申请" 数据 **/
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.a_link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>
